import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useAppContext } from "../../../../library/lib_context";
import {Button, Dropdown, Modal, Popup, Table} from 'semantic-ui-react'
import './details.css';


export default function() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { var_authentication, set_authentication } = useAppContext();

    const [ var_activetab, set_activetab ] = useState('TIMESHEET')


    //  variable listeners ---------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------




    //  API calls ------------------------------------------------------------------------------------------------------




    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }



    // RENDER PAGE =====================================================================================================

    if(var_authentication.authenticated){
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div className="content-topmenu_icon"><img src="../../../icons/checkmark 60px (494847).svg" alt="checkmark icon"></img></div>
                    Approvals | Flight Ops | Employee
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_approvals">
                        <div className="content-card-content">
                        Approvals
                        </div>
                    </div>

                    <div className="content-tabs">
                        <div className={"content-tabs-item " + (var_activetab === 'TIMESHEET' ? 'content-tabs-item-active' : '')} onClick={()=>onClick_tab('TIMESHEET')}>Timesheet</div>
                        <div className={"content-tabs-item " + (var_activetab === 'COMPENSATION' ? 'content-tabs-item-active' : '')} onClick={()=>onClick_tab('COMPENSATION')}>Compensation Summary</div>
                        <div className={"content-tabs-item " + (var_activetab === 'PAYROLL' ? 'content-tabs-item-active' : '')} onClick={()=>onClick_tab('PAYROLL')}>Payroll Outline</div>
                    </div>

                    <div className={"content-card " + (var_activetab !== 'TIMESHEET' ? 'content-card-hide' : '')}>
                        <div className="content-card-content">
                        Timesheet
                        </div>
                    </div>

                    <div className={"content-card " + (var_activetab !== 'COMPENSATION' ? 'content-card-hide' : '')}>
                        <div className="content-card-content">
                            Compensation Summary
                        </div>
                    </div>

                    <div className={"content-card " + (var_activetab !== 'PAYROLL' ? 'content-card-hide' : '')}>
                        <div className="content-card-content">
                            Payroll Outline
                        </div>
                    </div>
                </div>

            </div>
        );
    }else{
        return (
            <div></div>
        );
    }
}