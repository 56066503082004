import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Modal } from "semantic-ui-react";

import CMP_ITEM from "./cmp_item/cmp_item"

import './handbook.css';


export default function() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [var_categories, set_categories] = useState([]);
    const [var_items, set_items] = useState([]);

    const [ var_cmp_item_open, set_cmp_item_open ] = useState(false);
    const [ var_item_id, set_item_id ] = useState('');
    const [ var_item_title, set_item_title ] = useState('');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populatecategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populatecategories(){
        try {
            let categories = await API_get_categories();
            set_categories(categories);
            populateitems(categories[0].id)
        } catch (e) {
            console.log(e);
        }
    }

    async function populateitems(var_categoryid){
        try {
            let categoryitems = await API_get_items(var_categoryid);
            set_items(categoryitems);
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_categories(){
        return API.get('kbaops_documents', 'get-handbook-categories');
    }

    function API_get_items(var_categoryid){
        return API.get('kbaops_documents', 'get-handbook-items/'+var_categoryid);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_openSection(var_categoryid){
        populateitems(var_categoryid);
    }

    function onClick_openitem(var_id, var_title){
        set_item_id(var_id);
        set_item_title(var_title);
        set_cmp_item_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className="public__wrapper">
            <div className="handbook__categories">
                {var_categories.map((item, i) =>
                    <div className='handbook__category' onClick={() => onClick_openSection(item.id)}>
                        {item.category}
                    </div>
                )}
            </div>
            <div className="handbook__items">
                {var_items.map((item, i) =>
                    <div key={i} className='handbook__item' onClick={() => onClick_openitem(item.id, item.item_title)}>
                        <div className='handbook__item__title'>{item.item_title}</div>
                        <div className='handbook__item__updated'>last modified: {item.lastmodified}</div>
                    </div>
                )}
            </div>



            {/***** MODAL: ITEM **********************************************************************************/}

            <Modal id="cmp_item"
                   dimmer={'inverted'}
                   open={var_cmp_item_open}
                   onClose={() => set_cmp_item_open(false)}>
                <CMP_ITEM
                    var_item_id={var_item_id}
                    var_item_title={var_item_title}
                    set_mdl_open={set_cmp_item_open}
                />
            </Modal>

            {/***** END MODAL: ITEM ******************************************************************************/}

        </div>
    );
}