import React from "react";
import { Route, Switch } from "react-router-dom";
import login from "./containers/login/login";
import loginreset from "./containers/loginreset/loginreset";
import handbook from "./containers/handbook/handbook";
import home from "./containers/home/home";
import myprofile from "./containers/myprofile/myprofile";
import employees from "./containers/employees/employees";
import schedules from "./containers/schedules/schedules";
import flightfollowing from "./containers/flightfollowing/flightfollowing";
import timesheets from "./containers/timesheets/timesheets";
import approvals from "./containers/approvals/approvals";
import approvals_flightops from "./containers/approvals/flightops/flightops";
import approvals_flightops_details from "./containers/approvals/flightops/details/details";
import approvals_mtc from "./containers/approvals/mtc/mtc";
import approvals_salary from "./containers/approvals/salary/salary";
import checks from "./containers/checks/checks";
import reports from "./containers/reports/reports";
import reports_covid19 from "./containers/reports/covid19/covid19";
import reports_companyproperty from "./containers/reports/companyproperty/companyproperty";
import memos from "./containers/memos/memos";
import reference from "./containers/reference/reference";
import admin from "./containers/admin/admin";
import notfound from "./containers/notfound/notfound";
import AppliedRoute from "./components/applied_route";


    export default function Routes({ appProps }) {
        return (
            <Switch>
                <AppliedRoute path="/" exact component={home} appProps={appProps}/>
                <AppliedRoute path="/login" exact component={login} appProps={appProps}/>
                <AppliedRoute path="/handbook" exact component={handbook} appProps={appProps}/>
                <AppliedRoute path="/loginreset/:passwordresetid" exact component={loginreset} appProps={appProps}/>
                <AppliedRoute path="/home" exact component={home} appProps={appProps}/>
                <AppliedRoute path="/myprofile" exact component={myprofile} appProps={appProps}/>
                <AppliedRoute path="/employees" exact component={employees} appProps={appProps}/>
                <AppliedRoute path="/schedules" exact component={schedules} appProps={appProps}/>
                <AppliedRoute path="/flightfollowing" exact component={flightfollowing} appProps={appProps}/>
                <AppliedRoute path="/timesheets" exact component={timesheets} appProps={appProps}/>
                <AppliedRoute path="/approvals" exact component={approvals} appProps={appProps}/>
                <AppliedRoute path="/approvals/flightops" exact component={approvals_flightops} appProps={appProps}/>
                <AppliedRoute path="/approvals/flightops/details/:employeeid" exact component={approvals_flightops_details} appProps={appProps}/>
                <AppliedRoute path="/approvals/mtc" exact component={approvals_mtc} appProps={appProps}/>
                <AppliedRoute path="/approvals/salary" exact component={approvals_salary} appProps={appProps}/>
                <AppliedRoute path="/checks" exact component={checks} appProps={appProps}/>
                <AppliedRoute path="/reports" exact component={reports} appProps={appProps}/>
                <AppliedRoute path="/reports/covid19" exact component={reports_covid19} appProps={appProps}/>
                <AppliedRoute path="/reports/companyproperty" exact component={reports_companyproperty} appProps={appProps}/>
                <AppliedRoute path="/memos" exact component={memos} appProps={appProps}/>
                <AppliedRoute path="/reference" exact component={reference} appProps={appProps}/>
                <AppliedRoute path="/admin" exact component={admin} appProps={appProps}/>
                <Route component={notfound} />
            </Switch>
        );
    }