import React, { useEffect, useState } from "react";
import { useAppContext } from "../../library/lib_context";
import './schedules.css';
import {Button, Dropdown, Popup} from "semantic-ui-react";
import Excel from "../../components/export_excel";
import {Table} from "semantic-ui-react/dist/commonjs/collections/Table";


export default function() {

    const { var_authentication, set_authentication } = useAppContext();


    // RENDER PAGE =====================================================================================================

    if(var_authentication.authenticated){
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div className="content-topmenu_icon"><img src="icons/calendar 60px (494847).svg" alt="calendar icon"></img></div>
                    Schedules
                    <div className="content-topmenu_submenu">
                        <div className="content-topmenu_submenu-item content-topmenu_submenu-item-selected"><div className="content-topmenu_submenu-itemtext">Contracts</div></div>
                        <div className="content-topmenu_submenu-item"><div className="content-topmenu_submenu-itemtext">Aircraft</div></div>
                        <div className="content-topmenu_submenu-item"><div className="content-topmenu_submenu-itemtext">FO</div></div>
                        <div className="content-topmenu_submenu-item"><div className="content-topmenu_submenu-itemtext">MTC</div></div>
                        <div className="content-topmenu_submenu-item"><div className="content-topmenu_submenu-itemtext">Training</div></div>
                        <div className="content-topmenu_submenu-item"><div className="content-topmenu_submenu-itemtext">Vacation</div></div>
                    </div>

                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_covid19">
                        <div className="content-card-header">
                            <div className="content-card-header_title">Schedules</div>
{/*
                            <div className="content-card-header_submenu">
                                <div className="content-card-header_submenu-item content-card-header_submenu-item-selected"><div className="content-card-header_submenu-itemtext">Contracts</div></div>
                                <div className="content-card-header_submenu-item"><div className="content-card-header_submenu-itemtext">Aircraft</div></div>
                                <div className="content-card-header_submenu-item"><div className="content-card-header_submenu-itemtext">FO</div></div>
                                <div className="content-card-header_submenu-item"><div className="content-card-header_submenu-itemtext">MTC</div></div>
                                <div className="content-card-header_submenu-item"><div className="content-card-header_submenu-itemtext">Training</div></div>
                                <div className="content-card-header_submenu-item"><div className="content-card-header_submenu-itemtext">Vacation</div></div>
                            </div>
*/}
                            <div className="content-card-header_options">

                            </div>
                        </div>
                        <div className="content-card-content">
                            <div className="content-card-content-options">
                                <div className="content-card-content-options_filters">

                                </div>
                                <div className="content-card-content-options_data">

                                </div>
                            </div>
                            <div className="cc_covid19_wrapper">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        return (
            <div></div>
        );
    }
}