import Routes from "./routes";
import React from "react";
import Moment from "moment";
import { useEffect, useState} from "react";
import { withRouter, useHistory } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { AppContext } from "./library/lib_context";

import 'semantic-ui-css/semantic.min.css'
import './App.css';

const authentication = {
  authenticated: false,
  authenticating: true,
  cognito_id: '',
  user_id: '',
  classification_id: '',
  status_id: '',
  firstname: '',
  lastname: '',
  classification: ''
};


function App() {

  const var_history = useHistory();

  const [var_authentication, set_authentication] = useState(authentication);
  const [var_time, set_time] = useState(Moment().utc().format('HH:mm'));
  const [var_date, set_date] = useState(Moment().utc().format('DD MMM YY'));


  useEffect(() => {

    authenticateuser();

    const var_interval = setInterval(() => {
      set_time(Moment().utc().format('HH:mm'));
      set_date(Moment().utc().format('DD MMM YY').toUpperCase());
    }, 60000);
    return () => clearInterval(var_interval);

  }, []);


  async function authenticateuser(){

    var authentication = { ...var_authentication };
    authentication['authenticating'] = true;

    try {
      await Auth.currentSession();
      let var_AWScurrentuser = await Auth.currentUserInfo();
      let individual = await API_get_employeecognito(var_AWScurrentuser.username);
      authentication['authenticated'] = true;
      authentication['authenticating'] = false;
      authentication['cognito_id'] = var_AWScurrentuser.username;
      authentication['user_id'] = individual[0]['id'];
      authentication['classification_id'] = individual[0]['classification_id'];
      authentication['status_id'] = individual[0]['status_id'];
      authentication['firstname'] = individual[0]['firstname'];
      authentication['lastname'] = individual[0]['lastname'];
      authentication['classification'] = individual[0]['classification'];
      set_authentication(authentication);

    }catch(var_AWSmessage) {
      if(var_AWSmessage === 'No current user'){
        authentication['authenticated'] = false;
        set_authentication(authentication);

        if(window.location.pathname.slice(-11) === '/loginreset'){
          var_history.push(window.location.pathname);
        }if(window.location.pathname.slice(-9) === '/handbook'){
          var_history.push(window.location.pathname);
        }else{
          var_history.push("/login");
        }
      }
    }

    authentication['authenticating'] = false;
    set_authentication(authentication);
  }


  function API_get_employeecognito(cognitousername){
    return API.get('kbaops_employees', 'get-employeecognito/'+cognitousername);
  }


  async function onClick_logout(){
    await Auth.signOut();
    var authentication = { ...var_authentication };
    authentication['authenticated'] = false;
    set_authentication(authentication);
    var_history.push("/login");
  }

  function onClick_sidemenu(var_page){
    var_history.push(var_page);
  }


  // RENDER APP ========================================================================================================

  return (
      <div>
        {var_authentication.authenticated ?
          <div className="app-container">
            <div className="app-topmenu">

              <div className="app-topmenu-logo"><img src="/icons/logo (3f3e3d).svg" alt="Kenn Borek logo"></img></div>

              <div className="app-topmenu-menu"></div>

              <div className="app-topmenu-UTC">
                <div className="app-topmenu-UTC-icon"><img src="/icons/clock 60px (494847).svg" alt="clock icon"></img></div>
                <div className="app-topmenu-UTC-time">{var_time}</div>
                <div className="app-topmenu-UTC-date">{var_date}</div>
              </div>

              <div className="app-topmenu-loggedin">
                <div className="app-topmenu-loggedin-icon"><img src="/icons/profile 60px (494847).svg" alt="profile icon"></img></div>
                <div className="app-topmenu-loggedin-username">{var_authentication.firstname} {var_authentication.lastname}</div>
                <div className="app-topmenu-loggedin-classification">{var_authentication.classification}</div>
              </div>

              <div className="app-topmenu-logout" onClick={() => onClick_logout()}><img src="/icons/power 60px (f15b4f).svg"
                                                                                        onMouseOver={e => (e.currentTarget.src = "/icons/power 60px (de3c2f).svg")}
                                                                                        onMouseOut={e => (e.currentTarget.src = "/icons/power 60px (f15b4f).svg")} alt="clock icon"></img></div>

            </div>

            <div className="app-content">
              <div className="app-sidenav">
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/location 30px (ffffff).svg" alt="location icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/home`)}>Home</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/profile 30px (ffffff).svg" alt="profile icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/myprofile`)}>My Profile</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/profiles 30px (ffffff).svg" alt="profiles icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/employees`)}>Employees</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/calendar 30px (ffffff).svg" alt="calendar icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/schedules`)}>Schedules</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/airplane 30px (ffffff).svg" alt="airplane icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/flightfollowing`)}>Flight Following</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/clock 30px (ffffff).svg" alt="clock icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/timesheets`)}>Timesheets</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/checkmark 30px (ffffff).svg" alt="checkmark icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/approvals`)}>Approvals</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/checks 30px (ffffff).svg" alt="checks icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/checks`)}>Checks</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/report 30px (ffffff).svg" alt="report icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/reports`)}>Reports</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/broadcast 30px (ffffff).svg" alt="broadcast icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/memos`)}>Memos</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/book 30px (ffffff).svg" alt="book icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/reference`)}>Reference Documents</div>
                </div>
                <div className="app-sidenav-item">
                  <div className="app-sidenav-item_icon"><img src="/icons/gear 30px (ffffff).svg" alt="gear icon"></img></div>
                  <div className="app-sidenav-item_text" onClick={() => onClick_sidemenu(`/admin`)}>Administration</div>
                </div>
              </div>

              <div className="app-sidecontent">
                  <AppContext.Provider value={{var_authentication, set_authentication}}>
                    <Routes/>
                  </AppContext.Provider>
              </div>
            </div>
          </div>
        : <div>
              <AppContext.Provider value={{ var_authentication, set_authentication }}>
                <Routes/>
              </AppContext.Provider>
            </div>
        }
      </div>
  );
}

export default withRouter(App);
