import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import {Button, Dropdown, Modal, Popup, Table} from 'semantic-ui-react'
import { useAppContext } from "../../../library/lib_context";
import Excel from "../../../components/export_excel";

import './covid19.css';


export default function() {

    const var_months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    const { var_authentication } = useAppContext();
    const [var_covid19, set_covid19] = useState([]);
    const [var_covid19_processing, set_covid19_processing] = useState(false);
    const [var_covid19months, set_covid19months] = useState([]);
    const [var_covid19months_processing, set_covid19months_processing] = useState(false);
    const [var_covid19month, set_covid19month] = useState('');

    const [var_mdl_admindistribution_open, set_mdl_admindistribution_open] = useState(false);
    const [var_reportdistribution, set_reportdistribution] = useState([]);
    const [var_reportdistribution_processing, set_reportdistribution_processing] = useState(false);
    const [var_reportdistribution_current, set_reportdistribution_current] = useState([]);


    var var_dates = [];
    var currentmonth = new Date(var_covid19month.substr(0,4), var_covid19month.substr(5,2), 0);
    for(let i=1; i<currentmonth.getDate()+1; i++){
        var tmp = {};
        tmp['label']=i;
        tmp['data']=var_months[currentmonth.getMonth()]+'_'+i;
        var_dates.push(tmp);
    }


    useEffect(() => {
        if(var_authentication.authenticated){
            populatecovid19months();
        }
    },[]);

    useEffect(() => {
        if(var_covid19month) {
            populatecovid19();
        }
    },[var_covid19month]);

    useEffect(() => {
        if(var_reportdistribution_current.target) {
            updatereportdistribution();
        }
    },[var_reportdistribution_current]);


    async function populatecovid19(){
        set_covid19_processing(true);
        try {
            let covid19 = await API_get_covid19();
            set_covid19(covid19);
        } catch (e) {
            console.log(e);
        }
        set_covid19_processing(false);
    }

    async function populatecovid19months(){
        set_covid19months_processing(true);
        try {
            let covid19months = await API_get_covid19months();
            set_covid19months(covid19months);
            set_covid19month(new Date().getUTCFullYear()+'-'+("0" + (new Date().getUTCMonth() + 1)).slice(-2));
        } catch (e) {
            console.log(e);
        }
        set_covid19months_processing(false);
    }

    async function populatereportdistribution(){
        try {
            let reportdistribution = await API_get_reportdistribution();
            set_reportdistribution(reportdistribution);
        } catch (e) {
            console.log(e);
        }
        set_reportdistribution_processing(false);
    }

    async function updatereportdistribution(){
        set_reportdistribution_processing(true);
        try {
            await API_put_reportdistribution();
            populatereportdistribution();
        } catch (e) {
            console.log(e);
        }
    }


    function API_get_covid19(){
        return API.get('kbaops_reports', 'get-covid19/'+var_covid19month);
    }

    function API_get_covid19months(){
        return API.get('kbaops_reports', 'get-covid19months');
    }

    function API_get_reportdistribution(){
        return API.get('kbaops_reports', 'get-reportdistribution/15');
    }

    function API_put_reportdistribution(){
        return API.put('kbaops_reports', 'put-reportdistribution',{body: var_reportdistribution_current});
    }


    function onClick_refresh(){
        populatecovid19();
    }

    function onChange_covid19month(event, { value }){
        set_covid19month(value);
    }

    function onOpen_mdl_admindistribution(){
        populatereportdistribution();
        set_mdl_admindistribution_open(true);
    }

    function onDrop_assigned(event){
        event.preventDefault();
        event.stopPropagation()
        const reportdistribution_current = { ...var_reportdistribution_current };
        reportdistribution_current.target = 'ASSIGNED';
        reportdistribution_current.report_id = 15;
        reportdistribution_current.lastmodifiedby = var_authentication.firstname+' '+var_authentication.lastname;
        set_reportdistribution_current(reportdistribution_current);
    }

    function onDragOver_assigned(event){
        event.preventDefault();
        event.stopPropagation();
    }

    function onDrop_unassigned(event){
        event.preventDefault();
        event.stopPropagation();
        const reportdistribution_current = { ...var_reportdistribution_current };
        reportdistribution_current.target = 'UNASSIGNED';
        reportdistribution_current.lastmodifiedby = var_authentication.firstname+' '+var_authentication.lastname;
        set_reportdistribution_current(reportdistribution_current);
    }

    function onDragOver_unassigned(event){
        event.preventDefault();
        event.stopPropagation();
    }

    function onDrag_tag(event, item){
        event.preventDefault();
        set_reportdistribution_current(item);
    }



    // RENDER PAGE =====================================================================================================

    if(var_authentication.authenticated){
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div className="content-topmenu_icon"><img src="/icons/report 60px (494847).svg" alt="report icon"></img></div>
                    Reports
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_covid19">
                        <div className="content-card-header">
                            <div className="content-card-header_title">Covid 19 Daily Self Monitoring</div>
                            <div className="content-card-header_options">
                                <Popup trigger={<Button className="btn_icononly"><img className="btn_icon" src="/icons/elipses 30px (a2a2a1).svg" alt="download icon"></img></Button>}
                                       flowing
                                       hoverable
                                       position='bottom right'>
                                    <div className="pop_admin">
                                        <div className="pop_admin-item"><img className="pop_admin-item_icon" src="/icons/gear 30px (a2a2a1).svg" alt="gear icon"></img>Setup</div>
                                        <div className="pop_admin-item" onClick={() => onOpen_mdl_admindistribution()}><img className="pop_admin-item_icon" src="/icons/clock 30px (a2a2a1).svg" alt="clock icon"></img>Distribution</div>
                                        <div className="pop_admin-item"><img className="pop_admin-item_icon" src="/icons/hourglass 30px (a2a2a1).svg" alt="hourglass icon"></img>History</div>
                                    </div>
                                </Popup>
                            </div>
                        </div>
                        <div className="content-card-content">
                            <div className="content-card-content-options">
                                <div className="content-card-content-options_filters">
                                    <Dropdown
                                        selection
                                        className='months'
                                        name='months'
                                        label='Months'
                                        value={var_covid19month || ''}
                                        options={var_covid19months}
                                        onChange={onChange_covid19month}
                                        placeholder='loading months...'
                                    />
                                </div>
                                <div className="content-card-content-options_data">
                                <Button className="btn_icononly" onClick={()=>onClick_refresh()}><img className="btn_icon" src="/icons/refresh 30px (a2a2a1).svg" alt="download icon"></img></Button>
                                <Excel excel_data={var_covid19} excel_name={'Covid-19'}/>
                                </div>
                            </div>
                            <div className="cc_covid19_wrapper">
                                <Table compact className={(var_covid19_processing ? ' tbl_loading' : '')}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>EMPLOYEEE</Table.HeaderCell>
                                            {var_dates.map((item_x, x) =>
                                                <Table.HeaderCell key={x}>{item_x.label}</Table.HeaderCell>
                                            )}
                                        </Table.Row>
                                    </Table.Header>

                                    {!var_covid19months_processing &&
                                    <Table.Body>
                                        {var_covid19.map((item, i) =>
                                            <Table.Row key={i}>
                                                <Table.Cell>{item.EMPLOYEE}</Table.Cell>
                                                {var_dates.map((item_x, x) =>
                                                    <Table.Cell key={x} className="tbl_cell_dashed">
                                                        <div className="tbl_cell_statusicon">   {item[item_x.data] === 'PASS' && <img src="/icons/x 30px (51a823).svg" alt="pass icon"></img>}
                                                                                                {item[item_x.data] === 'ALERT' && <img src="/icons/checkmark 30px (c0392b).svg" alt="alert icon"></img>}
                                                                                                {item[item_x.data] === '-' && <img src="/icons/circle 30px (e7e7e7).svg" alt="rotation icon"></img>}
                                                        </div>
                                                    </Table.Cell>
                                                )}
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                    }
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>


                {/***** MODAL: ADMIN DISTRIBUTION ********************************************************************/}

                <Modal id="mdl_admindistribution"
                       dimmer={'inverted'}
                       open={var_mdl_admindistribution_open}
                       onClose={() => set_mdl_admindistribution_open(false)}
                >
                    <div className="modal-header">Automated Distribution List</div>
                    <div className="modal-content">
                        <div className="modal-content-instructions">The distribution list for this report is made up of all the assigned classifications as listed below.  To modify the distribution list, drag and drop classifications between the assigned and unassigned lists as needed.</div>
                        <div className="mdl_admindistribution_classificationswrapper">
                            <div className="mdl_admindistribution_classificationsassigned_wrapper">
                                <div className="mdl_admindistribution_classifications_title">Assigned Classifications</div>
                                <div className={"mdl_admindistribution_classificationsassigned" + (var_reportdistribution_processing ? ' tbl_loading' : '')} onDrop={(e) => onDrop_assigned(e)} onDragOver={(e) => onDragOver_assigned(e)}>
                                    {var_reportdistribution.map((item, i) =>
                                        <div key={i} >{item.distribution_id &&
                                            <div className="tag" draggable onDrag={(e)=>onDrag_tag(e,item)}>{item.code}</div>
                                        }</div>
                                    )}
                                </div>
                            </div>
                            <div className="mdl_admindistribution_classificationsunassigned_wrapper">
                                <div className="mdl_admindistribution_classifications_title">Unassigned Classifications</div>
                                <div className={"mdl_admindistribution_classificationsunassigned" + (var_reportdistribution_processing ? ' tbl_loading' : '')} onDrop={(e) => onDrop_unassigned(e)} onDragOver={(e) => onDragOver_unassigned(e)}>
                                    {var_reportdistribution.map((item, i) =>
                                        <div key={i} >{item.distribution_id < 1 &&
                                        <div className="tag" draggable onDrag={(e)=>onDrag_tag(e,item)}> {item.code}</div>
                                        }</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button className="btn_secondary" onClick={() => set_mdl_admindistribution_open(false)}>CLOSE</Button>
                        </div>
                    </div>
                </Modal>

                {/***** END MODAL: ADMIN DISTRIBUTION ****************************************************************/}

            </div>
        );
    }else{
        return (
            <div></div>
        );
    }
}