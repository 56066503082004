import React, { useEffect, useState} from "react";
import { Button, Form, Input } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../library/lib_context";
import { API, Auth } from "aws-amplify";

import "./loginreset.css";


export default function(appProps) {

    const var_history = useHistory();

    const { var_authentication, set_authentication } = useAppContext();

    const [var_login_step, set_login_step] = useState('VALIDATEPASSWORDRESET');
    const [var_login, set_login] = useState([]);

    const [var_validatepassword_length, set_validatepassword_length] = useState(false);
    const [var_validatepassword_lowercase, set_validatepassword_lowercase] = useState(false);
    const [var_validatepassword_uppercase, set_validatepassword_uppercase] = useState(false);
    const [var_validatepassword_number, set_validatepassword_number] = useState(false);
    const [var_validatepassword_specialcharacter, set_validatepassword_specialcharacter] = useState(false);
    const [var_newpassword_ready, set_newpassword_ready] = useState(false);
    const [var_newpassword_processing, set_newpassword_processing] = useState(false);


    useEffect(() => {
        validatepasswordreset();
    },[]);

    useEffect(() => {
        if (var_validatepassword_length && var_validatepassword_lowercase && var_validatepassword_uppercase && var_validatepassword_number && var_validatepassword_specialcharacter) {
            set_newpassword_ready(true)
        } else {
            set_newpassword_ready(false)
        }
    }, [var_validatepassword_length, var_validatepassword_lowercase, var_validatepassword_uppercase, var_validatepassword_number, var_validatepassword_specialcharacter]);

    useEffect(() => {
        validatenewpassword();
    }, [var_login.newpassword]);


    async function validatepasswordreset(){

        await Auth.signOut();
        var authentication = { ...var_authentication };
        authentication['authenticated'] = false;
        set_authentication(authentication);

        try {
            let var_validatepasswordreset = await API_get_validatepasswordreset();
            if(var_validatepasswordreset[0]['validate']===0){
                set_login_step('VALIDATEPASSWORDFAIL');
            }else{
                set_login_step('VALIDATEPASSWORDSUCCESS');
            }
            console.log(var_validatepasswordreset[0]);
        } catch (e) {
            console.log(e);
        }
    }


    function API_get_validatepasswordreset(){
        return API.get('kbaops_employees', 'get-validatepasswordreset/'+appProps.match.params.passwordresetid);
    }

    function API_put_validatepasswordreset(){
        return API.put('kbaops_employees', '/put-validatepasswordreset/'+appProps.match.params.passwordresetid, { body: var_login });
    }


    function onChange_login_input(event){
        const login = { ...var_login };
        login[event.target.name] = event.target.value;
        set_login(login);
    }

    function validatenewpassword(){
        if(var_login.newpassword) {
            let password=var_login.newpassword;

            if (password.length >= 8) {
                set_validatepassword_length(true);
            } else {
                set_validatepassword_length(false);
            }

            if (/[a-z]/.test(password)) {
                set_validatepassword_lowercase(true);
            } else {
                set_validatepassword_lowercase(false);
            }

            if (/[A-Z]/.test(password)) {
                set_validatepassword_uppercase(true);
            } else {
                set_validatepassword_uppercase(false);
            }

            if (/[0-9]/.test(password)) {
                set_validatepassword_number(true);
            } else {
                set_validatepassword_number(false);
            }

            if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
                set_validatepassword_specialcharacter(true);
            } else {
                set_validatepassword_specialcharacter(false);
            }
        }else{
            set_validatepassword_length(false);
            set_validatepassword_lowercase(false);
            set_validatepassword_uppercase(false);
            set_validatepassword_number(false);
            set_validatepassword_specialcharacter(false);
        }
    }

    async function onClick_newpassword(){
        if(var_newpassword_ready) {
            set_newpassword_processing(true);

            try {
                let resetpasswordrequest = await API_put_validatepasswordreset();
                if (resetpasswordrequest === 'success') {
                    set_login_step('RESETPASS');
                } else {
                    set_login_step('RESETFAIL');
                }
            } catch (error) {
                console.log(error);
            }

            set_newpassword_processing(false);
        }
    }

    function onClick_backtologin(){
        var_history.push('/login');
    }



    // RENDER MODULE ===================================================================================================

    if(!var_authentication.authenticated) {
        return (
            <div className="login_wrapper">
                <div className="login">
                    <div className="login_header"><img src="/icons/logo (ffffff).svg" alt="Kenn Borek logo"></img></div>
                    <div className="login_content">

                        {/* VALIDATE PASSWORD RESET LINK ------------------------------------------------------------*/}

                        {var_login_step === 'VALIDATEPASSWORDRESET' &&
                        <div>
                            <div className="alert system alert_validatepasswordreset">
                                <div className="alert_icon">
                                    <div className="alert_icon_inner"><img src="/icons/flag 30px (F4A700).svg" alt="warning icon"></img></div>
                                </div>
                                <div className="alert_text">Validating your password reset link...</div>
                            </div>
                        </div>
                        }


                        {/* VALIDATE PASSWORD FAILED ----------------------------------------------------------------*/}

                        {var_login_step === 'VALIDATEPASSWORDFAIL' &&
                        <div>
                            <div className="alert warning">
                                <div className="alert_icon">
                                    <div className="alert_icon_inner"><img src="/icons/x 30px (c0392b).svg" alt="warning icon"></img></div>
                                </div>
                                <div className="alert_text">This link does not appear to be valid and we are unable to process your password reset request at this time.</div>
                            </div>
                        </div>
                        }


                        {/* RESET TEMPORARY PASSWORD ----------------------------------------------------------------*/}

                        {var_login_step === 'VALIDATEPASSWORDSUCCESS' &&
                        <div>
                            <div className="alert info alert_newpassword">
                                <div className="alert_icon">
                                    <div className="alert_icon_inner"><img src="/icons/x 30px (1859F2).svg" alt="warning icon"></img></div>
                                </div>
                                <div className="alert_text">Thank you for validating the password reset link. <span>Please submit a new password below.</span></div>
                            </div>

                            <Form>
                                <Form.Group>
                                    <Form.Field
                                        className='newpassword'
                                        id='newpassword'
                                        name='newpassword'
                                        value={var_login.newpassword || ''}
                                        control={Input}
                                        type="password"
                                        onChange={onChange_login_input}
                                        label='New Password'
                                        placeholder='New Password...'
                                    />
                                </Form.Group>
                                <div className="passwordvalidation">
                                    <div className="passwordvalidation_item">
                                        {var_validatepassword_length ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark 30px (51a823).svg" alt="password status"></img></div>
                                            :
                                            <div
                                                className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="/icons/x 30px (c0392b).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">8 characters long</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {var_validatepassword_uppercase ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark 30px (51a823).svg" alt="password status"></img></div>
                                            :
                                            <div
                                                className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="/icons/x 30px (c0392b).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">upper case</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {var_validatepassword_lowercase ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark 30px (51a823).svg" alt="password status"></img></div>
                                            :
                                            <div
                                                className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="/icons/x 30px (c0392b).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">lower case</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {var_validatepassword_number ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark 30px (51a823).svg" alt="password status"></img></div>
                                            :
                                            <div
                                                className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="/icons/x 30px (c0392b).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">number</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {var_validatepassword_specialcharacter ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark 30px (51a823).svg" alt="password status"></img></div>
                                            :
                                            <div
                                                className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="/icons/x 30px (c0392b).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">special character</div>
                                    </div>
                                </div>
                            </Form>
                            <div className="login_buttons">
                                {!var_newpassword_processing ?
                                    <Button className={"btn_primary " + (var_newpassword_ready ? 'btn_active' : '')} onClick={() => onClick_newpassword()}>SUBMIT</Button>
                                    :
                                    <Button loading id="btn_login" className="btn_primary btn_active">SUBMIT</Button>
                                }
                            </div>
                        </div>
                        }

                        {/* RESET PASSWORD FAILED -------------------------------------------------------------------*/}

                        {var_login_step === 'RESETFAIL' &&
                        <div>
                            <div className="alert warning alert_resetfail">
                                <div className="alert_icon">
                                    <div className="alert_icon_inner"><img src="/icons/x 30px (c0392b).svg" alt="warning icon"></img></div>
                                </div>
                                <div className="alert_text">An error occured and we are unable to process your password reset request at this time.</div>
                            </div>
                            <Button className="btn_tertiary" onClick={() => onClick_backtologin()}>Back to login</Button>
                        </div>
                        }

                        {/* RESET PASSWORD PASSED -------------------------------------------------------------------*/}

                        {var_login_step === 'RESETPASS' &&
                        <div>
                            <div className="alert success alert_resetfail">
                                <div className="alert_icon">
                                    <div className="alert_icon_inner"><img src="/icons/checkmark 30px (51a823).svg" alt="warning icon"></img></div>
                                </div>
                                <div className="alert_text">You have successfully reset your password. <span>Click the link below to login.</span></div>
                            </div>
                            <Button className="btn_tertiary" onClick={() => onClick_backtologin()}>Go to login</Button>
                        </div>
                        }

                    </div>
                </div>
            </div>
        )
    }else{
        return (
            <div></div>
        );
    }


};
