import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";

import './cmp_item.css';



export default function({ var_item_id, var_item_title }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const [var_handbookitem, set_handbookitem] = useState([]);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populateitem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populateitem(){
        try {
            let handbookitem = await API_get_item();
            set_handbookitem(handbookitem[0]);
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_item(){
        return API.get('kbaops_documents', 'get-handbook-item/'+var_item_id);
    }



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER CMP ======================================================================================================

    return (
        <>
            <div className='handbook--item--wrapper'>
                <div className="modal--content">
                    <div className="modal--content--handbooktext" dangerouslySetInnerHTML={ { __html: var_handbookitem.item_text}}/>
                </div>
            </div>
        </>
    )

}