import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import {Button, Dropdown, Modal, Popup, Table} from 'semantic-ui-react'
import { useAppContext } from "../../../library/lib_context";
import Excel from "../../../components/export_excel";

import './companyproperty.css';


export default function() {

    const { var_authentication } = useAppContext();
    const [var_companyproperty, set_companyproperty] = useState([]);
    const [var_processing, set_processing] = useState(false);



    useEffect(() => {
        if(var_authentication.authenticated){
            populatecompanyproperty();
        }
    },[]);



    async function populatecompanyproperty(){
        set_processing(true);
        try {
            let companyproperty = await API_get_companyproperty();
            set_companyproperty(companyproperty);
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }



    function API_get_companyproperty(){
        return API.get('kbaops_reports', 'get-companyproperty');
    }



    function onClick_refresh(){
        populatecompanyproperty();
    }



    // RENDER PAGE =====================================================================================================

    if(var_authentication.authenticated){
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div className="content-topmenu_icon"><img src="/icons/report 60px (494847).svg" alt="report icon"></img></div>
                    Reports
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_covid19">
                        <div className="content-card-header">
                            <div className="content-card-header_title">Company Property</div>
                        </div>
                        <div className="content-card-content">
                            <div className="content-card-content-options">
                                <div className="content-card-content-options_data">
                                <Button className="btn_icononly" onClick={()=>onClick_refresh()}><img className="btn_icon" src="/icons/refresh 30px (a2a2a1).svg" alt="download icon"></img></Button>
                                <Excel excel_data={var_companyproperty} excel_name={'Company Property'}/>
                                </div>
                            </div>
                            <div className="cc_companyproperty_wrapper">
                                <Table compact className={(var_processing ? ' tbl_loading' : '')}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>EMPLOYEEE</Table.HeaderCell>
                                            <Table.HeaderCell>DESCRIPTION</Table.HeaderCell>
                                            <Table.HeaderCell>ID NUMBER</Table.HeaderCell>
                                            <Table.HeaderCell>COMMENTS</Table.HeaderCell>
                                            <Table.HeaderCell>EXPIRES</Table.HeaderCell>
                                            <Table.HeaderCell>RETURNED</Table.HeaderCell>
                                            <Table.HeaderCell>RECEIVED BY</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    {!var_processing &&
                                    <Table.Body>
                                        {var_companyproperty.map((item, i) =>
                                            <Table.Row key={i}>
                                                <Table.Cell>{item.employee}</Table.Cell>
                                                <Table.Cell>{item.description}</Table.Cell>
                                                <Table.Cell>{item.idnumber}</Table.Cell>
                                                <Table.Cell>{item.comments}</Table.Cell>
                                                <Table.Cell>{item.expires}</Table.Cell>
                                                <Table.Cell>{item.returned}</Table.Cell>
                                                <Table.Cell>{item.receivedby}</Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                    }
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }else{
        return (
            <div/>
        );
    }
}