import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../library/lib_context";
import './reports.css';


export default function() {

    const history = useHistory();
    const [var_reports, set_reports] = useState([]);
    const { var_authentication } = useAppContext();


    useEffect(() => {
        populatereports();
    },[]);


    async function populatereports(){
        try {
            let reports = await API_get_reports();
            set_reports(reports);
        } catch (e) {
            console.log(e);
        }
    }


    function API_get_reports(){
        return API.get('kbaops_reports', 'get-reports');
    }


    function onClick_report(var_directory){
        history.push('reports/'+var_directory);
    }


    // RENDER PAGE =====================================================================================================

    if(var_authentication.authenticated){
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div className="content-topmenu_icon"><img src="icons/report 60px (494847).svg" alt="report icon"></img></div>
                    Reports
                </div>
                <div className="content-wrapper">
                    <div className="reports-wrapper">
                        {var_reports.map((item, i) =>
                            <div key={i} className="content-card-noheader reports-item" onClick={()=>onClick_report(item.directory)}>
                                <div className="content-card-content">
                                    <div className="reports-name">{item.name}</div>
                                    <div className="reports-description">{item.description}</div>
                                </div>
                                <div className="content-card-footer">
                                    <div className={"reports-item-footer-item " + (item.scheduled<1 && "reports-item-footer-item_inactive")}>
                                        <div className="reports-item-footer-item_icon"><img src="icons/clock 20px (494847).svg" alt="clock icon"></img></div>
                                    </div>
                                    <div className="reports-item-footer-item">
                                        <div className="reports-item-footer-item_icon"><img src="icons/profiles 20px (494847).svg" alt="profiles icon"></img></div>
                                        <div className="reports-item-footer-item_text">00</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }else{
        return (
            <div></div>
        );
    }
}