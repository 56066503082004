import React, { useEffect, useState } from "react";
import { useAppContext } from "../../library/lib_context";
import './memos.css';


export default function() {

    const { var_authentication, set_authentication } = useAppContext();


    // RENDER PAGE =====================================================================================================

    if(var_authentication.authenticated){
        return (
        <div className="content">
            <div className="content-topmenu">
                <div className="content-topmenu_icon"><img src="icons/broadcast 60px (494847).svg" alt="broadcast icon"></img></div>
                Memos
            </div>
            <div className="content-wrapper">

            </div>
        </div>
    );
    }else{
        return (
            <div></div>
        );
    }
}