import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../library/lib_context";
import './approvals.css';


export default function() {

    const history = useHistory();
    const { var_authentication, set_authentication } = useAppContext();

    function onClick_approval(var_directory){
        history.push('approvals/'+var_directory);
    }

    if(var_authentication.authenticated){
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div className="content-topmenu_icon"><img src="icons/checkmark 60px (494847).svg" alt="checkmark icon"></img></div>
                    Approvals
                </div>
                <div className="content-wrapper">
                    <div className="approvals-wrapper">
                        <div className="content-card-noheader approvals-item" onClick={()=>onClick_approval('flightops')}>
                            <div className="content-card-content">
                                <div className="reports-name">Flight Ops</div>
                                <div className="reports-description"> </div>
                            </div>
                            <div className="content-card-footer">
                            </div>
                        </div>
                        <div className="content-card-noheader approvals-item" onClick={()=>onClick_approval('mtc')}>
                            <div className="content-card-content">
                                <div className="reports-name">MTC</div>
                                <div className="reports-description"> </div>
                            </div>
                            <div className="content-card-footer">
                            </div>
                        </div>
                        <div className="content-card-noheader approvals-item" onClick={()=>onClick_approval('salary')}>
                            <div className="content-card-content">
                                <div className="reports-name">Salary</div>
                                <div className="reports-description"> </div>
                            </div>
                            <div className="content-card-footer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        return (
            <div></div>
        );
    }
}