import React, { useEffect, useState} from "react";
import { Button, Form, Input } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../library/lib_context";
import { API, Auth } from "aws-amplify";

import "./login.css";


export default function() {

    const var_history = useHistory();

    const { var_authentication, set_authentication } = useAppContext();

    const [var_AWSuser, set_AWS_user] = useState(null);
    const [var_login_step, set_login_step] = useState('LOGIN');
    const [var_login, set_login] = useState([]);
    const [var_login_warning, set_login_warning] = useState('');
    const [var_login_processing, set_login_processing] = useState(false);
    const [var_login_ready, set_login_ready] = useState(false);

    const [var_validatepassword_length, set_validatepassword_length] = useState(false);
    const [var_validatepassword_lowercase, set_validatepassword_lowercase] = useState(false);
    const [var_validatepassword_uppercase, set_validatepassword_uppercase] = useState(false);
    const [var_validatepassword_number, set_validatepassword_number] = useState(false);
    const [var_validatepassword_specialcharacter, set_validatepassword_specialcharacter] = useState(false);
    const [var_newpassword_ready, set_newpassword_ready] = useState(false);
    const [var_newpassword_processing, set_newpassword_processing] = useState(false);


    useEffect(()=>{
       if(var_authentication.authenticated === true){
           var_history.push("/");
       }
    },[]);

    useEffect(() => {
        if (var_validatepassword_length && var_validatepassword_lowercase && var_validatepassword_uppercase && var_validatepassword_number && var_validatepassword_specialcharacter) {
            set_newpassword_ready(true)
        } else {
            set_newpassword_ready(false)
        }
    }, [var_validatepassword_length, var_validatepassword_lowercase, var_validatepassword_uppercase, var_validatepassword_number, var_validatepassword_specialcharacter]);

    useEffect(() => {
        validatenewpassword();
    }, [var_login.newpassword]);


    function API_get_employeecognito(cognitousername){
        return API.get('kbaops_employees', '/get-employeecognito/'+cognitousername);
    }

    function API_post_passwordreset(){
        return API.post('kbaops_employees', 'post-passwordreset',{ body: var_login });
    }


    function onChange_login_input(event){
        if(event.target.name==='email'){
            event.target.value=event.target.value.toLowerCase()
        }
        const login = { ...var_login };
        login[event.target.name] = event.target.value;
        set_login(login);
        set_login_ready(true);
    }

    async function onClick_login() {
        if(var_login_ready) {

            set_login_warning('');
            set_login_processing(true);

            try {
                let var_AWSauth = await Auth.signIn(var_login.email, var_login.password);

                set_AWS_user(var_AWSauth);

                if(var_AWSauth.challengeName === 'NEW_PASSWORD_REQUIRED'){
                    set_login_step('NEWPASSWORD');
                }else{
                    let var_AWScurrentuser = await Auth.currentUserInfo();
                    let individual = await API_get_employeecognito(var_AWScurrentuser.username);
                    var authentication = { ...var_authentication };
                    authentication['authenticated'] = true;
                    authentication['cognito_id'] = var_AWScurrentuser.username;
                    authentication['user_id'] = individual[0]['id'];
                    authentication['classification_id'] = individual[0]['classification_id'];
                    authentication['status_id'] = individual[0]['status_id'];
                    authentication['firstname'] = individual[0]['firstname'];
                    authentication['lastname'] = individual[0]['lastname'];
                    authentication['classification'] = individual[0]['name'];
                    set_authentication(authentication);

                    var_history.push("/");
                }
            } catch (var_AWSmessage) {
                console.log(var_AWSmessage)
                if (var_AWSmessage.message === 'Incorrect username or password.') {
                    set_login_warning('INCORRECT');
                }
            }

            set_login_processing(false);

        }
    }

    function validatenewpassword(){
        if(var_login.newpassword) {
            let password=var_login.newpassword;

            if (password.length >= 8) {
                set_validatepassword_length(true);
            } else {
                set_validatepassword_length(false);
            }

            if (/[a-z]/.test(password)) {
                set_validatepassword_lowercase(true);
            } else {
                set_validatepassword_lowercase(false);
            }

            if (/[A-Z]/.test(password)) {
                set_validatepassword_uppercase(true);
            } else {
                set_validatepassword_uppercase(false);
            }

            if (/[0-9]/.test(password)) {
                set_validatepassword_number(true);
            } else {
                set_validatepassword_number(false);
            }

            if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
                set_validatepassword_specialcharacter(true);
            } else {
                set_validatepassword_specialcharacter(false);
            }
        }else{
            set_validatepassword_length(false);
            set_validatepassword_lowercase(false);
            set_validatepassword_uppercase(false);
            set_validatepassword_number(false);
            set_validatepassword_specialcharacter(false);
        }
    }

    async function onClick_newpassword(){
        if(var_newpassword_ready) {
            set_newpassword_processing(true);

            try {
                await Auth.completeNewPassword(var_AWSuser, var_login.newpassword);
                await Auth.signIn(var_login.email, var_login.newpassword);
                let var_AWScurrentuser = await Auth.currentUserInfo();
                let individual = await API_get_employeecognito(var_AWScurrentuser.username);
                var authentication = {...var_authentication};
                authentication['authenticated'] = true;
                authentication['cognito_id'] = var_AWScurrentuser.username;
                authentication['user_id'] = individual[0]['id'];
                authentication['classification_id'] = individual[0]['classification_id'];
                authentication['status_id'] = individual[0]['status_id'];
                authentication['firstname'] = individual[0]['firstname'];
                authentication['lastname'] = individual[0]['lastname'];
                authentication['classification'] = individual[0]['name'];
                set_authentication(authentication);

                var_history.push("/");
            } catch (error) {
                console.log(error);
            }

            set_newpassword_processing(false);
        }
    }

    function onClick_forgotpassword(){
        const login = { ...var_login };
        login['password'] = '';
        set_login(login);

        set_login_step('FORGOTPASSWORD');
    }

    function onClick_backtologin(){
        set_login_step('LOGIN');
    }

    function onClick_submitforgotpassword(){
        API_post_passwordreset();
        set_login_step('FORGOTPASSWORDSENT');
    }


    // RENDER MODULE ===================================================================================================

    if(!var_authentication.authenticated) {
        return (
            <div className="login_wrapper">
                <div className="login">
                    <div className="login_header"><img src="/icons/logo (3f3e3d).svg" alt="Kenn Borek logo"></img></div>
                    <div className="login_content">


                        {/* LOGIN -----------------------------------------------------------------------------------*/}

                        {var_login_step === 'LOGIN' &&
                        <div>
                            <Form>
                                <Form.Group>
                                    <Form.Field
                                        className='email'
                                        id='email'
                                        name='email'
                                        value={var_login.email || ''}
                                        control={Input}
                                        onChange={onChange_login_input}
                                        label='Email'
                                        placeholder='Email...'
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='password'
                                        id='password'
                                        name='password'
                                        value={var_login.password || ''}
                                        control={Input}
                                        type="password"
                                        onChange={onChange_login_input}
                                        label='Password'
                                        placeholder='Password...'
                                    />
                                </Form.Group>
                            </Form>

                            {var_login_warning === 'INCORRECT' &&
                            <div className="alert warning">
                                <div className="alert_icon">
                                    <div className="alert_icon_inner"><img src="/icons/x 30px (c0392b).svg" alt="warning icon"></img></div>
                                </div>
                                <div className="alert_text">Incorrect username or
                                    password. <span>Please try again.</span></div>
                            </div>
                            }
                            <div className="login_buttons">
                                {!var_login_processing ?
                                    <Button className={"btn_primary " + (var_login_ready ? 'btn_active' : '')} onClick={() => onClick_login()}>LOGIN</Button>
                                    :
                                    <Button loading className="btn_primary btn_active">LOGIN</Button>
                                }
                                <Button className="btn_tertiary" onClick={() => onClick_forgotpassword()}>Forgot password</Button>
                            </div>
                        </div>
                        }


                        {/* RESET TEMPORARY PASSWORD ----------------------------------------------------------------*/}

                        {var_login_step === 'NEWPASSWORD' &&
                        <div>
                            <div className="alert info alert_newpassword">
                                <div className="alert_icon">
                                    <div className="alert_icon_inner"><img src="/icons/x 30px (1859F2).svg" alt="warning icon"></img></div>
                                </div>
                                <div className="alert_text">Before logging in for the first time, you need to create a secure and unique password. <span>Please submit a new password below.</span></div>
                            </div>

                            <Form>
                                <Form.Group>
                                    <Form.Field
                                        className='newpassword'
                                        id='newpassword'
                                        name='newpassword'
                                        value={var_login.newpassword || ''}
                                        control={Input}
                                        type="password"
                                        onChange={onChange_login_input}
                                        label='New Password'
                                        placeholder='New Password...'
                                    />
                                </Form.Group>
                                <div className="passwordvalidation">
                                    <div className="passwordvalidation_item">
                                        {var_validatepassword_length ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark 30px (51a823).svg" alt="password status"></img></div>
                                            :
                                            <div
                                                className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="/icons/x 30px (c0392b).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">8 characters long</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {var_validatepassword_uppercase ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark 30px (51a823).svg" alt="password status"></img></div>
                                            :
                                            <div
                                                className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="/icons/x 30px (c0392b).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">upper case</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {var_validatepassword_lowercase ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark 30px (51a823).svg" alt="password status"></img></div>
                                            :
                                            <div
                                                className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="/icons/x 30px (c0392b).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">lower case</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {var_validatepassword_number ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark 30px (51a823).svg" alt="password status"></img></div>
                                            :
                                            <div
                                                className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="/icons/x 30px (c0392b).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">number</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {var_validatepassword_specialcharacter ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark 30px (51a823).svg" alt="password status"></img></div>
                                            :
                                            <div
                                                className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="/icons/x 30px (c0392b).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">special character</div>
                                    </div>
                                </div>
                            </Form>
                            <div className="login_buttons">
                                {!var_newpassword_processing ?
                                    <Button className={"btn_primary " + (var_newpassword_ready ? 'btn_active' : '')} onClick={() => onClick_newpassword()}>SUBMIT</Button>
                                    :
                                    <Button loading id="btn_login" className="btn_primary btn_active">SUBMIT</Button>
                                }
                                <Button className="btn_tertiary" onClick={() => onClick_backtologin()}>Back to login</Button>
                            </div>
                        </div>
                        }


                        {/* REQUEST PASSWORD RESET ------------------------------------------------------------------*/}

                        {var_login_step === 'FORGOTPASSWORD' &&
                        <div>
                            <div className="alert info alert_forgotpassword">
                                <div className="alert_icon">
                                    <div className="alert_icon_inner"><img src="/icons/x 30px (1859F2).svg" alt="warning icon"></img></div>
                                </div>
                                <div className="alert_text">To reset your password, we need to verify the email address used to login to the account.  <span>Please submit the email address you use to login to KBAOPS.</span></div>
                            </div>
                            <Form>
                                <Form.Group>
                                    <Form.Field
                                        className='email'
                                        id='email'
                                        name='email'
                                        value={var_login.email || ''}
                                        control={Input}
                                        onChange={onChange_login_input}
                                        label='Login Email'
                                        placeholder='Email...'
                                    />
                                </Form.Group>
                            </Form>

                            <div className="login_buttons">
                                {!var_login_processing ?
                                    <Button className={"btn_primary " + (var_login_ready ? 'btn_active' : '')} onClick={() => onClick_submitforgotpassword()}>SUBMIT</Button>
                                    :
                                    <Button loading className="btn_primary btn_active">SUBMIT</Button>
                                }
                                <Button className="btn_tertiary" onClick={() => onClick_backtologin()}>Back to login</Button>
                            </div>
                        </div>
                        }


                        {/* PASSWORD RESET LINK SENT ----------------------------------------------------------------*/}

                        {var_login_step === 'FORGOTPASSWORDSENT' &&
                        <div>
                            <div className="alert info alert_forgotpassword">
                                <div className="alert_icon">
                                    <div className="alert_icon_inner"><img src="/icons/x 30px (1859F2).svg" alt="warning icon"></img></div>
                                </div>
                                <div className="alert_text">A link to help you reset your password has been sent to {var_login.email}. <span>Please check your email (and spam folder) for the link.</span></div>
                            </div>
                            <Button className="btn_tertiary" onClick={() => onClick_backtologin()}>Back to login</Button>
                        </div>
                        }

                    </div>
                </div>
            </div>
        )
    }else{
        return (
            <div></div>
        );
    }


};
