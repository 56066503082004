import React, { useEffect, useState } from "react";
import { useAppContext } from "../../library/lib_context";
import './employees.css';


export default function() {

    const { var_authentication, set_authentication } = useAppContext();


    // RENDER PAGE =====================================================================================================

    if(var_authentication.authenticated){
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div className="content-topmenu_icon"><img src="icons/profiles 60px (494847).svg" alt="profiles icon"></img></div>
                    Employees
                </div>
                <div className="content-wrapper">

                </div>
            </div>
        );
    }else{
        return (
            <div></div>
        );
    }
}