import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useAppContext } from "../../../library/lib_context";
import {Button, Dropdown, Modal, Popup, Table} from 'semantic-ui-react'
import './mtc.css';


export default function() {

    const { var_authentication, set_authentication } = useAppContext();
    const [var_employees, set_employees] = useState([]);
    const [var_employees_processing, set_employees_processing] = useState(false);

    const [ var_adminclassifications_open, set_adminclassifications_open ] = useState(false);
    const [var_employeegroups, set_employeegroups] = useState([]);
    const [var_employeegroups_ready, set_employeegroups_ready] = useState(false);
    const [var_employeegroups_processing, set_employeegroups_processing] = useState(false);

    useEffect(() => {
        if(var_authentication.authenticated){
            populate_employees();
        }
    },[]);



    async function populate_employees(){
        set_employees_processing(true);
        try {
            let employees = await API_get_employees();
            set_employees(employees);
        } catch (e) {
            console.log(e);
        }
        set_employees_processing(false);
    }

    async function populate_employeegroups(){
        try {
            let employeegroups = await API_get_employeegroups();
            set_employeegroups(employeegroups);
        } catch (e) {
            console.log(e);
        }
    }

    async function update_employeegroups(){
        set_employeegroups_processing(true);
        try {
            await API_put_employeegroups();
            set_adminclassifications_open(false);
            set_employeegroups_processing(false);
            populate_employees();
        } catch (e) {
            console.log(e);
        }
    }



    function API_get_employees(){
        return API.get('kbaops_approvals', 'get-employees_mtc/2020-06');
    }

    function API_get_employeegroups(){
        return API.get('kbaops_approvals', 'get-employeegroups/MTC');
    }

    function API_put_employeegroups(){
        return API.put('kbaops_approvals', 'put-employeegroups/MTC', {body: var_employeegroups});
    }

    function onClick_openadminclassifications(){
        set_employeegroups_ready(false);
        set_employeegroups_processing(false);
        set_adminclassifications_open(true);
        populate_employeegroups();
    }

    function onClick_tag(i){
        let employeegroups = [...var_employeegroups];

        if(employeegroups[i]['action'] === '' || !employeegroups[i]['action']){
            if(employeegroups[i]['approvalclassification_id'] >0){
                employeegroups[i]['action'] = 'DELETE';
            }
            if(employeegroups[i]['approvalclassification_id'] <1){
                employeegroups[i]['action'] = 'INSERT';
            }
            employeegroups[i]['lastmodifiedby'] = var_authentication.firstname+' '+var_authentication.lastname;
        }else{
            employeegroups[i]['action'] = '';
        }
        set_employeegroups_ready(true);
        set_employeegroups(employeegroups);
    }

    function onClick_saveadminclassifications(){
        update_employeegroups();
    }



    // RENDER PAGE =====================================================================================================

    if(var_authentication.authenticated){
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div className="content-topmenu_icon"><img src="../icons/checkmark 60px (494847).svg" alt="checkmark icon"></img></div>
                    Approvals | MTC
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_covid19">
                        <div className="content-card-header">
                            <div className="content-card-header_title">MTC Employees</div>
                            <div className="content-card-header_options">
                                <Popup trigger={<Button className="btn_icononly"><img className="btn_icon" src="/icons/elipses 30px (a2a2a1).svg" alt="download icon"></img></Button>}
                                       flowing
                                       hoverable
                                       position='bottom right'>
                                    <div className="pop_admin">
                                        <div className="pop_admin-item" onClick={()=>onClick_openadminclassifications()}><img className="pop_admin-item_icon" src="/icons/gear 30px (a2a2a1).svg" alt="gear icon"></img>Classifications</div>
                                    </div>
                                </Popup>
                            </div>
                        </div>
                        <div className="content-card-content">
                            <div className="content-card-content-options">
                                <div className="content-card-content-options_filters">
                                    {/*
                                    <Dropdown
                                        selection
                                        className='months'
                                        name='months'
                                        label='Months'
                                        value={var_covid19month || ''}
                                        options={var_covid19months}
                                        onChange={onChange_covid19month}
                                        placeholder='loading months...'
                                    />
*/}
                                </div>
                                <div className="content-card-content-options_data">

                                </div>
                            </div>
                            <div className="cc_covid19_wrapper">
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell>STATUS</Table.HeaderCell>
                                            <Table.HeaderCell>EMPLOYEEE</Table.HeaderCell>
                                            <Table.HeaderCell>CLASSIFICATION</Table.HeaderCell>
                                            <Table.HeaderCell>DAYS</Table.HeaderCell>
                                            <Table.HeaderCell>EMP</Table.HeaderCell>
                                            <Table.HeaderCell>MGR</Table.HeaderCell>
                                            <Table.HeaderCell>ADMIN</Table.HeaderCell>
                                            <Table.HeaderCell>PRM</Table.HeaderCell>
                                            <Table.HeaderCell>PAYROLL</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    {!var_employees_processing &&
                                    <Table.Body>
                                        {var_employees.map((item, i) =>
                                            <Table.Row key={i}>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell className="tbl_cell_statusicon">
                                                    {item.status_id === 1 && <img src="/icons/checkmark 30px (51a823).svg" alt="active"></img>}
                                                    {item.status_id === 2 && <img src="/icons/x 30px (c0392b).svg" alt="active"></img>}
                                                    {item.status_id === 4 && <img src="/icons/pause 30px (ffbe3d).svg" alt="active"></img>}
                                                </Table.Cell>
                                                <Table.Cell>{item.lastname+', '+item.firstname}</Table.Cell>
                                                <Table.Cell>{item.classification}</Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                    }

                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

                {/***** MODAL: ADMIN CLASSIFICATIONS ***********************************************************************/}

                <Modal id="mdl_adminclassifications"
                       dimmer={'inverted'}
                       open={var_adminclassifications_open}
                       onClose={() => set_adminclassifications_open(false)}
                >
                    <div className="modal-header">MTC Employee List</div>
                    <div className="modal-content">
                        <div className="modal-content-instructions">The MTC employee list is made up of the assigned classifications as listed below.  To modify the list, tap the desired classification to select or deselect as required.</div>
                        <div className="modal-tag-panel">
                            {var_employeegroups.map((item, i) =>
                                <div key={i} >
                                    <div className={"tag " + (((item.approvalclassification_id > 0 || item.action === 'INSERT') && item.action !== 'DELETE') ? "active" : "")} onClick={()=>onClick_tag(i)}>{item.code}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button className="btn_secondary" onClick={() => set_adminclassifications_open(false)}>CLOSE</Button>
                            {!var_employeegroups_processing ?
                                <Button className={"btn_primary " +(var_employeegroups_ready ? "btn_active" : "")} onClick={() => onClick_saveadminclassifications()}>SAVE</Button>
                                :
                                <Button loading className="btn_primary btn_active">SAVE</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/***** END MODAL: ADMIN DISTRIBUTION ****************************************************************/}
            </div>
        );
    }else{
        return (
            <div></div>
        );
    }
}