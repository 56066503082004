import React from 'react'
import { Button } from 'semantic-ui-react'
import FileSaver from 'file-saver';
import XLSX from 'xlsx';

export default function({excel_data,excel_name}){

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    function onClick_excel(excel_data, excel_name){

        //  create filename --------------------------------------------------------------------------------------------

        const date = new Date();
        const file_name = excel_name+' ('+date.getFullYear()+'-'+("0" + (date.getMonth() + 1)).slice(-2)+'-'+("0" + date.getDate()).slice(-2)+' '+("0" + date.getHours()).slice(-2)+':'+("0" + date.getMinutes()).slice(-2)+':'+("0" + date.getSeconds()).slice(-2)+').xlsx';

        //  create spreadsheet headers ---------------------------------------------------------------------------------

        let headers = [];
        let headersarray = [];
        for (let [value] of Object.entries(excel_data[0])) {
            headersarray.push(value.toUpperCase());
        }
        headers.push(headersarray);


        //  create excel spreadsheet -----------------------------------------------------------------------------------

        const wb = XLSX.utils.book_new();
        var ws = XLSX.utils.aoa_to_sheet(headers);
        XLSX.utils.sheet_add_json(ws, excel_data, {skipHeader: true, origin: "A2"});
        XLSX.utils.book_append_sheet(wb, ws, excel_name);

        //  write file -------------------------------------------------------------------------------------------------

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, file_name);

    }

    return (
        <Button onClick={() => onClick_excel(excel_data, excel_name)}><img className="btn_icon" src="/icons/download 30px (a2a2a1).svg" alt="download icon"></img>EXPORT</Button>
    )
}